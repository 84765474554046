<template>
  <v-container id="deliveries" fluid tag="section">
    <orders-view :orders="orders" :last-update="lastUpdate" :error="error" :no-result-title="$t('delivery.list.empty.title')" :no-result-description="$t('delivery.list.empty.description')"
                 @refresh="refresh"
    />
  </v-container>
</template>

<script>
  import { secondToHHmm } from '@/util/DateUtil';
  import { getPageName, handleRequestError } from '@/services/common/Http';
  import OrdersView from '@/views/components/business/order/OrdersView';

  export default {
    name: 'Delivery',
    components: { OrdersView },
    data () {
      return {
        error: '',
        lastUpdate: 0,
        orders: []
      };
    },
    methods: {
      parseWaitingTime (time) {
        if (time.value === 0) {
          return undefined;
        }

        const diff = (time.avg === 0 ? 0 : ((time.avg - time.value) / Math.abs(time.avg)) * 100);
        let color = 'green';
        let icon = 'timer-sand-empty';
        if (diff < -10) { // %
          color = 'red';
          icon = 'timer-sand-full';
        } else if (diff < 0) {
          color = 'orange';
          icon = 'timer-sand-empty';
        }
        return '<span style="color:' + color + ';"><span class="mdi mdi-' + icon + '"></span> ' + secondToHHmm(time.value) + '</span>'
          + ' / <span class="mdi mdi-medal-outline"></span> ' + secondToHHmm(time.value > time.avg ? time.avg : time.value * 0.75);
      },
      async refresh () {
        await this.refreshOrders();
        await this.refreshInfo();
      },
      async refreshInfo () {
        let info;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'location/performances/delivery');
          if (response.data !== undefined && response.data.waitingTime !== undefined) {
            info = '';
            const waitingTime = this.parseWaitingTime(response.data.waitingTime);
            if (waitingTime !== undefined) {
              info += waitingTime;
            }
          }
        } catch (error) {
          await handleRequestError(error, this.$router);
        }
        this.setInfo(info);
      },
      async refreshOrders () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/deliveries/');
          this.orders = response.data;
          this.lastUpdate = Date.now();
          this.error = '';
        } catch (error) {
          this.error = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('delivery.error'));
        }
      },
      setInfo (info) {
        this.$store.commit('SET_PAGE_INFO_LINE2', {
          pageName: getPageName(),
          value: info
        });
      }
    }
  };
</script>
